import {LogLevel} from 'angular-auth-oidc-client';
import {EnvironmentVars} from './environment.model';

export const environment: EnvironmentVars = {
  production: false,
  featureFlags: {
    loginEnabled: true,
    maintenanceInfo: false,
    contactForm: true,
    swissPass: true,
    contacts: true,
    dutyContacts: true,
    plannedDuties: true,
    militaryPostalAddress: true,
    personalStatus: true,
    emergencyContacts: true,
    languageSkills: true,
    occupation: true,
    dutyDetailsPublicTransport: true,
    dutyDetailsPostalAddress: true,
    wzpModalEnabled: true,
    serviceDays: true
  },
  portalUrl: 'https://ref.portal-armee.ch/msa-portal-ui',
  localDevelopment: false,
  EXT_URL_RS_PLANNER: 'https://ref.portal-armee.ch/rekrutenschule-planer/',
  EXT_URL_TEAM_FINDER: 'https://ref.portal-armee.ch/team-finder/',
  backendLogging: {
    active: true,
    substituteMessage: false
  },
  TF_ENV: 'ref.',
  api: {
    adminQueryUrl: 'https://api-ref.portal-armee.ch/msa-duty-service',
    dutyServiceUrl: 'https://api-ref.portal-armee.ch/msa-duty-service',
    personDataUrl: 'https://api-ref.portal-armee.ch/msa-person-data-service',
    portalServiceUrl: 'https://api-ref.portal-armee.ch/msa-portal-service',
    notificationUrl: 'https://api-ref.portal-armee.ch/msa-notification-service'
  },
  oidc: {
    authority: 'https://identity-r.bit.admin.ch/realms/msa-internal',
    clientId: 'msa-frontend-v2-client',
    scope: 'openid offline_access',
    silentRenew: true,
    useRefreshToken: false,
    ignoreNonceAfterRefresh: true,
    logLevel: LogLevel.Debug
  }
};
